import { useEffect, useState } from 'react'
import Seo from 'src/components/seo/Seo'

function PageProductReview() {
  const [manufacturerCode, setManufacturerCode] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    setManufacturerCode(params.get('code'))
  }, [])

  return (
    <>
      <Seo />

      <iframe
        title="openvoice-iframe"
        scrolling="no"
        frameBorder="0"
        src={`https://reviews.decathlon.com/pt_BR/review/new/964/1/${manufacturerCode}#step1`}
        width="100%"
        height="1600px"
      >
        SEU NAVEGADOR NÃO SUPORTA IFRAMES
      </iframe>
    </>
  )
}

export default PageProductReview
